
    <template>
      <section class="content element-doc">
        <h2>Dialog 对话框</h2>
<p>在保留当前页面状态的情况下，告知用户并承载相关操作。</p>
<h3>基本用法</h3>
<p>Dialog 弹出一个对话框，适合需要定制性更大的场景。</p>
<demo-block>
        <div><p>需要设置<code>visible</code>属性，它接收<code>Boolean</code>，当为<code>true</code>时显示 Dialog。Dialog 分为两个部分：<code>body</code>和<code>footer</code>，<code>footer</code>需要具名为<code>footer</code>的<code>slot</code>。<code>title</code>属性用于定义标题，它是可选的，默认值为空。最后，本例还展示了<code>before-close</code>的用法。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-button type=&quot;text&quot; @click=&quot;dialogVisible = true&quot;&gt;点击打开 Dialog&lt;/el-button&gt;

&lt;el-dialog
  title=&quot;提示&quot;
  :visible.sync=&quot;dialogVisible&quot;
  width=&quot;30%&quot;
  :before-close=&quot;handleClose&quot;
  v-model:visible=&quot;dialogVisible&quot;
&gt;
  &lt;template v-slot:default&gt;
    &lt;span&gt;这是一段信息&lt;/span&gt;
  &lt;/template&gt;
  &lt;template v-slot:footer&gt;
    &lt;span class=&quot;dialog-footer&quot;&gt;
      &lt;el-button @click=&quot;dialogVisible = false&quot;&gt;取 消&lt;/el-button&gt;
      &lt;el-button type=&quot;primary&quot; @click=&quot;dialogVisible = false&quot;&gt;确 定&lt;/el-button&gt;
    &lt;/span&gt;
  &lt;/template&gt;
&lt;/el-dialog&gt;

&lt;script&gt;
  import { ref } from 'vue'
  import { Msgbox } from 'element3'
  export default {
    setup() {
      let dialogVisible = ref(false)

      function handleClose(done) {
        Msgbox
          .confirm('确认关闭？')
          .then((_) =&gt; {
            done()
          })
          .catch((_) =&gt; {})
      }
      // 暴露给模板
      return {
        dialogVisible,
        handleClose
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><div class="tip">
<p><code>before-close</code> 仅当用户通过点击关闭图标或遮罩关闭 Dialog 时起效。如果你在 <code>footer</code> 具名 slot 里添加了用于关闭 Dialog 的按钮，那么可以在按钮的点击回调函数里加入 <code>before-close</code> 的相关逻辑。</p>
</div>
<h3>自定义内容</h3>
<p>Dialog 组件的内容可以是任意的，甚至可以是表单，下面是应用了 Form 组件的样例。</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;!-- Form --&gt;
&lt;el-button type=&quot;text&quot; @click=&quot;dialogFormVisible = true&quot;
  &gt;打开嵌套表单的 Dialog&lt;/el-button
&gt;

&lt;el-dialog
  title=&quot;收货地址&quot;
  :visible.sync=&quot;dialogFormVisible&quot;
  v-model:visible=&quot;dialogFormVisible&quot;
&gt;
  &lt;template v-slot:default&gt;
    &lt;el-form :model=&quot;form&quot;&gt;
      &lt;el-form-item label=&quot;活动名称&quot; :label-width=&quot;formLabelWidth&quot;&gt;
        &lt;el-input v-model=&quot;form.name&quot; autocomplete=&quot;off&quot;&gt;&lt;/el-input&gt;
      &lt;/el-form-item&gt;
      &lt;el-form-item label=&quot;活动区域&quot; :label-width=&quot;formLabelWidth&quot;&gt;
        &lt;el-select v-model=&quot;form.region&quot; placeholder=&quot;请选择活动区域&quot;&gt;
          &lt;el-option label=&quot;区域一&quot; value=&quot;shanghai&quot;&gt;&lt;/el-option&gt;
          &lt;el-option label=&quot;区域二&quot; value=&quot;beijing&quot;&gt;&lt;/el-option&gt;
        &lt;/el-select&gt;
      &lt;/el-form-item&gt;
    &lt;/el-form&gt;
  &lt;/template&gt;
  &lt;template v-slot:footer&gt;
    &lt;div class=&quot;dialog-footer&quot;&gt;
      &lt;el-button @click=&quot;dialogFormVisible = false&quot;&gt;取 消&lt;/el-button&gt;
      &lt;el-button type=&quot;primary&quot; @click=&quot;dialogFormVisible = false&quot;
        &gt;确 定&lt;/el-button
      &gt;
    &lt;/div&gt;
  &lt;/template&gt;
&lt;/el-dialog&gt;

&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const state = reactive({
        dialogFormVisible: false,
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px'
      })

      return { ...toRefs(state) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>嵌套的 Dialog</h3>
<p>如果需要在一个 Dialog 内部嵌套另一个 Dialog，需要使用 <code>append-to-body</code> 属性。</p>
<demo-block>
        <div><p>正常情况下，我们不建议使用嵌套的 Dialog，如果需要在页面上同时显示多个 Dialog，可以将它们平级放置。对于确实需要嵌套 Dialog 的场景，我们提供了<code>append-to-body</code>属性。将内层 Dialog 的该属性设置为 true，它就会插入至 body 元素上，从而保证内外层 Dialog 和遮罩层级关系的正确。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-button type=&quot;text&quot; @click=&quot;outerVisible = true&quot;
  &gt;点击打开外层 Dialog&lt;/el-button
&gt;

&lt;el-dialog
  title=&quot;外层 Dialog&quot;
  :visible.sync=&quot;outerVisible&quot;
  v-model:visible=&quot;outerVisible&quot;
&gt;
  &lt;template v-slot:default&gt;
    &lt;el-dialog
      width=&quot;30%&quot;
      title=&quot;内层 Dialog&quot;
      :visible.sync=&quot;innerVisible&quot;
      v-model:visible=&quot;innerVisible&quot;
      append-to-body
    &gt;
    &lt;/el-dialog&gt;
  &lt;/template&gt;
  &lt;template v-slot:footer&gt;
    &lt;div class=&quot;dialog-footer&quot;&gt;
      &lt;el-button @click=&quot;outerVisible = false&quot;&gt;取 消&lt;/el-button&gt;
      &lt;el-button type=&quot;primary&quot; @click=&quot;innerVisible = true&quot;
        &gt;打开内层 Dialog&lt;/el-button
      &gt;
    &lt;/div&gt;
  &lt;/template&gt;
&lt;/el-dialog&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const outerVisible = ref(false)
      const innerVisible = ref(false)
      return {
        outerVisible,
        innerVisible
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>居中布局</h3>
<p>标题和底部可水平居中</p>
<demo-block>
        <div><p>将<code>center</code>设置为<code>true</code>即可使标题和底部居中。<code>center</code>仅影响标题和底部区域。Dialog 的内容是任意的，在一些情况下，内容并不适合居中布局。如果需要内容也水平居中，请自行为其添加 CSS。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-button type=&quot;text&quot; @click=&quot;centerDialogVisible = true&quot;
  &gt;点击打开 Dialog&lt;/el-button
&gt;

&lt;el-dialog
  title=&quot;提示&quot;
  :visible.sync=&quot;centerDialogVisible&quot;
  v-model:visible=&quot;centerDialogVisible&quot;
  width=&quot;30%&quot;
  center
&gt;
  &lt;template v-slot:default&gt;
    &lt;span&gt;需要注意的是内容是默认不居中的&lt;/span&gt;
  &lt;/template&gt;
  &lt;template v-slot:footer&gt;
    &lt;span class=&quot;dialog-footer&quot;&gt;
      &lt;el-button @click=&quot;centerDialogVisible = false&quot;&gt;取 消&lt;/el-button&gt;
      &lt;el-button type=&quot;primary&quot; @click=&quot;centerDialogVisible = false&quot;
        &gt;确 定&lt;/el-button
      &gt;
    &lt;/span&gt;
  &lt;/template&gt;
&lt;/el-dialog&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const centerDialogVisible = ref(false)
      return {
        centerDialogVisible
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><div class="tip">
<p>Dialog 的内容是懒渲染的，即在第一次被打开之前，传入的默认 slot 不会被渲染到 DOM 上。因此，如果需要执行 DOM 操作，或通过 <code>ref</code> 获取相应组件，请在 <code>open</code> 事件回调中进行。</p>
</div>
<div class="tip">
<p>如果 <code>visible</code> 属性绑定的变量位于 Vuex 的 store 内，那么 <code>.sync</code> 不会正常工作。此时需要去除 <code>.sync</code> 修饰符，同时监听 Dialog 的 <code>open</code> 和 <code>close</code> 事件，在事件回调中执行 Vuex 中对应的 mutation 更新 <code>visible</code> 属性绑定的变量的值。</p>
</div>
<h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>visible</td>
<td>是否显示 Dialog，支持 .sync 修饰符</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>title</td>
<td>Dialog 的标题，也可通过具名 slot （见下表）传入</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>width</td>
<td>Dialog 的宽度</td>
<td>string</td>
<td>—</td>
<td>50%</td>
</tr>
<tr>
<td>fullscreen</td>
<td>是否为全屏 Dialog</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>top</td>
<td>Dialog CSS 中的 margin-top 值</td>
<td>string</td>
<td>—</td>
<td>15vh</td>
</tr>
<tr>
<td>modal</td>
<td>是否需要遮罩层</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>modal-append-to-body</td>
<td>遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>append-to-body</td>
<td>Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>lock-scroll</td>
<td>是否在 Dialog 出现时将 body 滚动锁定</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>custom-class</td>
<td>Dialog 的自定义类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>close-on-click-modal</td>
<td>是否可以通过点击 modal 关闭 Dialog</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>close-on-press-escape</td>
<td>是否可以通过按下 ESC 关闭 Dialog</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>show-close</td>
<td>是否显示关闭按钮</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>before-close</td>
<td>关闭前的回调，会暂停 Dialog 的关闭</td>
<td>function(done)，done 用于关闭 Dialog</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>center</td>
<td>是否对头部和底部采用居中布局</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>destroy-on-close</td>
<td>关闭时销毁 Dialog 中的元素</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
</tbody>
</table>
<h3>Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>—</td>
<td>Dialog 的内容</td>
</tr>
<tr>
<td>title</td>
<td>Dialog 标题区的内容</td>
</tr>
<tr>
<td>footer</td>
<td>Dialog 按钮操作区的内容</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>open</td>
<td>Dialog 打开的回调</td>
<td>—</td>
</tr>
<tr>
<td>opened</td>
<td>Dialog 打开动画结束时的回调</td>
<td>—</td>
</tr>
<tr>
<td>close</td>
<td>Dialog 关闭的回调</td>
<td>—</td>
</tr>
<tr>
<td>closed</td>
<td>Dialog 关闭动画结束时的回调</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      type: "text",
      onClick: $event => (_ctx.dialogVisible = true)
    }, {
      default: _withCtx(() => [
        _createTextVNode("点击打开 Dialog")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_el_dialog, {
      title: "提示",
      visible: _ctx.dialogVisible,
      width: "30%",
      "before-close": _ctx.handleClose,
      "onUpdate:visible": $event => (_ctx.dialogVisible = $event)
    }, {
      default: _withCtx(() => [
        _createVNode("span", null, "这是一段信息")
      ]),
      footer: _withCtx(() => [
        _createVNode("span", { class: "dialog-footer" }, [
          _createVNode(_component_el_button, {
            onClick: $event => (_ctx.dialogVisible = false)
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $event => (_ctx.dialogVisible = false)
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "before-close", "onUpdate:visible"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  
            const { Msgbox } = Element3
          
  const democomponentExport = {
    setup() {
      let dialogVisible = ref(false)

      function handleClose(done) {
        Msgbox
          .confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      }
      // 暴露给模板
      return {
        dialogVisible,
        handleClose
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      type: "text",
      onClick: $event => (_ctx.dialogFormVisible = true)
    }, {
      default: _withCtx(() => [
        _createTextVNode("打开嵌套表单的 Dialog")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_el_dialog, {
      title: "收货地址",
      visible: _ctx.dialogFormVisible,
      "onUpdate:visible": $event => (_ctx.dialogFormVisible = $event)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, { model: _ctx.form }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "活动名称",
              "label-width": _ctx.formLabelWidth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.name,
                  "onUpdate:modelValue": $event => (_ctx.form.name = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["label-width"]),
            _createVNode(_component_el_form_item, {
              label: "活动区域",
              "label-width": _ctx.formLabelWidth
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.form.region,
                  "onUpdate:modelValue": $event => (_ctx.form.region = $event),
                  placeholder: "请选择活动区域"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "区域一",
                      value: "shanghai"
                    }),
                    _createVNode(_component_el_option, {
                      label: "区域二",
                      value: "beijing"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["label-width"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      footer: _withCtx(() => [
        _createVNode("div", { class: "dialog-footer" }, [
          _createVNode(_component_el_button, {
            onClick: $event => (_ctx.dialogFormVisible = false)
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $event => (_ctx.dialogFormVisible = false)
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ], 64))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const state = reactive({
        dialogFormVisible: false,
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px'
      })

      return { ...toRefs(state) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      type: "text",
      onClick: $event => (_ctx.outerVisible = true)
    }, {
      default: _withCtx(() => [
        _createTextVNode("点击打开外层 Dialog")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_el_dialog, {
      title: "外层 Dialog",
      visible: _ctx.outerVisible,
      "onUpdate:visible": $event => (_ctx.outerVisible = $event)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_dialog, {
          width: "30%",
          title: "内层 Dialog",
          visible: _ctx.innerVisible,
          "onUpdate:visible": $event => (_ctx.innerVisible = $event),
          "append-to-body": ""
        }, null, 8, ["visible", "onUpdate:visible"])
      ]),
      footer: _withCtx(() => [
        _createVNode("div", { class: "dialog-footer" }, [
          _createVNode(_component_el_button, {
            onClick: $event => (_ctx.outerVisible = false)
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $event => (_ctx.innerVisible = true)
          }, {
            default: _withCtx(() => [
              _createTextVNode("打开内层 Dialog")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const outerVisible = ref(false)
      const innerVisible = ref(false)
      return {
        outerVisible,
        innerVisible
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      type: "text",
      onClick: $event => (_ctx.centerDialogVisible = true)
    }, {
      default: _withCtx(() => [
        _createTextVNode("点击打开 Dialog")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_el_dialog, {
      title: "提示",
      visible: _ctx.centerDialogVisible,
      "onUpdate:visible": $event => (_ctx.centerDialogVisible = $event),
      width: "30%",
      center: ""
    }, {
      default: _withCtx(() => [
        _createVNode("span", null, "需要注意的是内容是默认不居中的")
      ]),
      footer: _withCtx(() => [
        _createVNode("span", { class: "dialog-footer" }, [
          _createVNode(_component_el_button, {
            onClick: $event => (_ctx.centerDialogVisible = false)
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $event => (_ctx.centerDialogVisible = false)
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const centerDialogVisible = ref(false)
      return {
        centerDialogVisible
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  